import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import Img from 'gatsby-image';

const ContainImages = styled(Grid)`
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 2rem;
  flex-direction: row;
  ${breakpoint.down('lg')`
  width:100%; height: 400px`};
  ${breakpoint.down('md')`
  display:none`};
  ${breakpoint.down('sm')`
  height: 400px`};
`;
const ImageSubintroBlock = styled(Grid)`
  width: 30%;
  max-width: 550px;
  position: absolute;
  z-index: 2;
  ${breakpoint.down('md')`
  max-width:50%;`};
  &:nth-child(1) {
    left: 0;
    top: 0;
    margin-top: -2%;
  }
  &:nth-child(2) {
    right: 0;
    top: 0;
    margin-top: -10%;
  }
`;

const ImageTestimonial = ({ images }) => {
  return (
    <ContainImages container>
      {images.map((image, index) => {
        return (
          <ImageSubintroBlock key={`ImageSubIntroBlock${index}`}>
            <Img fluid={image.childImageSharp.fluid} loading="lazy" />
          </ImageSubintroBlock>
        );
      })}
    </ContainImages>
  );
};
export default ImageTestimonial;
