import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import BlockSwipeable from './BlockSwipeable';

const TitleAvis = styled(Typography)`
  width: 100%;
  .biggerText {
    font-size: ${props => `${props.theme.typography.h2.fontSize}px`};
  }
`;
const BottomDots = styled(Grid)`
  padding-top: 0.5rem;
`;
const Slider = styled(Grid)`
  max-width: 500px;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  width: 100%;
  #sliderArticle {
    width: 100%;
  }
`;

const StyledMobileStepper = styled(MobileStepper)`
  background-color: transparent;
`;

const BlockSlider = ({
  slidesData,
  data,
  activeStep,
  maxSteps,
  handleStepChange,
}) => {
  return (
    <Slider container direction="column" justify="center">
      <TitleAvis
        variant="h5"
        dangerouslySetInnerHTML={{ __html: data.title.rawMarkdownBody }}
      />
      <BlockSwipeable
        activeStep={activeStep}
        data={data}
        slidesData={slidesData}
        handleStepChange={handleStepChange}
      />
      <BottomDots container item justify="center">
        <StyledMobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
        />
      </BottomDots>
    </Slider>
  );
};
export default BlockSlider;
