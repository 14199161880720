import { colors } from 'styles';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { breakpoint } from 'utils/mediaQueries';
import Img from 'gatsby-image';
import { Button } from 'components/basics';

const Quote = styled(Grid)`
  max-height: 100%;
  width: auto;
  text-align: center;
  ${breakpoint.down('md')`
   height: 355px`}
`;
const QuoteText = styled(Typography)`
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: normal;
  flex-grow: 1;
  width: 100%;
  ${breakpoint.down('md')`padding-bottom: 0;`}
  ${breakpoint.only('xs')`font-size: ${props =>
    `${props.theme.typography.body1.fontSize}px`};max-width: 200px;`}
  ${breakpoint.only('xxs')`font-size: ${props =>
    `${props.theme.typography.body1.fontSize}px`};max-width: 200px;`}
`;
const ImageSlider = styled(Img)`
  max-height: 50px;
  height: 100%;
  max-width: 180px;
  width: 100%;
  flex-grow: 1;
  img {
    object-fit: contain !important;
  }
`;

const VoirArticle = styled(Button)`
  margin-top: 2rem;
`;
const StyledLink = styled.a`
  color: ${colors.white};
  text-decoration: none;
`;
const BlockQuote = ({ index, step, activeStep, imageToShow }) => (
  <Quote
    container
    direction="column"
    alignItems="center"
    justify="space-evenly"
  >
    <QuoteText variant="h6">“{step.rawMarkdownBody}”</QuoteText>
    {Math.abs(activeStep - index) <= 2 ? (
      imageToShow ? (
        <ImageSlider fluid={imageToShow} />
      ) : null
    ) : null}
    <VoirArticle
      size={'large'}
      variant="contained"
      customBackgroundColor={colors.lightBlue}
      customHoverBackgroundColor={colors.shades.lightBlue.dark}
      mt={1}
      color="primary"
    >
      <StyledLink href={step.frontmatter.link} target={'__blank'}>
        <FormattedMessage id="button.sliderArticle" />
      </StyledLink>
    </VoirArticle>
  </Quote>
);
export default BlockQuote;
