import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import { Button } from 'components/basics';
import arrowLeft from 'images/indexPage/videoBlock/arrow_left.svg';
import arrowRight from 'images/indexPage/videoBlock/arrow_right.svg';
import BlockSlider from 'components/pages/Index/Video/Slider/BlockSlider';

const ContentBloc = styled(Grid)`
  position: relative;
`;

const ButtonContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  width: fit-content;
  ${breakpoint.only('down')`top: 50%`}
`;
const RightButton = styled(ButtonContainer)`
  right: 0;
`;
const LeftButton = styled(ButtonContainer)`
  left: 0;
  z-index: 1;
  ${breakpoint.down('md')`img {
    max-height: 30px;
  }`}
`;

const ButtonSlider = styled(Button)`
  padding: 0;
  img {
    max-height: 60px;
    height: 100%;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  ${breakpoint.down('sm')`img {
  max-height: 40px;
  }`}
`;

class SwipeableTextMobileStepper extends React.Component {
  state = {
    activeStep: 0,
    maxSteps: null,
    slidesData: null,
  };
  componentDidMount() {
    if (this.props.data) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data !== prevProps.data) {
      this.setData();
    }
  }

  setData = () => {
    const slidesData = this.props.data.markdownContent;
    const maxSteps = slidesData.length;
    this.setState({ maxSteps, slidesData });
  };

  handleNext = () => {
    const { activeStep, maxSteps } = this.state;
    this.setState(prevState => ({
      activeStep: activeStep === maxSteps - 1 ? 0 : prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    const { activeStep, maxSteps } = this.state;
    this.setState(prevState => ({
      activeStep: activeStep === 0 ? maxSteps - 1 : prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { data, lang } = this.props;
    const { maxSteps, slidesData } = this.state;
    const { activeStep } = this.state;
    return (
      <ContentBloc
        container
        item
        xs={12}
        sm={12}
        md={8}
        lg={6}
        justify="center"
        alignItems="center"
      >
        {slidesData && (
          <>
            <LeftButton>
              <ButtonSlider size="large" onClick={this.handleBack}>
                <img width="100%" src={arrowLeft} alt="Flèche gauche" loading="lazy" />
              </ButtonSlider>
            </LeftButton>
            <BlockSlider
              slidesData={slidesData}
              lang={lang}
              data={data}
              maxSteps={maxSteps}
              activeStep={activeStep}
              handleStepChange={this.handleStepChange}
            />
            <RightButton>
              <ButtonSlider size="large" onClick={this.handleNext}>
                <img width="100%" src={arrowRight} alt="Flèche droite" loading="lazy" />
              </ButtonSlider>
            </RightButton>
          </>
        )}
      </ContentBloc>
    );
  }
}
export default SwipeableTextMobileStepper;
