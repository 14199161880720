import React from 'react';
import { Grid } from '@material-ui/core';
import { YoutubeVideoEmbed } from '../../../basics';

const VideoBlock = () => {
  return (
    <Grid
      container
      alignItems="center"
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      justify="center"
    >
      <YoutubeVideoEmbed
        youtubeID={'0aj-kJ0T_U8'}
        youtubeTitle={'Presentation Q°emotion'}
        width={'560'}
        height={'315'}
      />
    </Grid>
  );
};
export default VideoBlock;
