import _ from 'lodash';
import React from 'react';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import BlockQuote from './BlockQuote';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const BlockSwipeable = ({ activeStep, handleStepChange, slidesData, data }) => (
  <AutoPlaySwipeableViews
    axis={'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
    springConfig={{
      duration: '0.35s',
      easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
      delay: '0s',
    }}
    interval={4000}
    id={'sliderArticle'}
  >
    {slidesData.map((step, index) => {
      const imageSlider = _.find(data.images, function(image) {
        return image.base === step.frontmatter.relatedImage;
      });
      const imageToShow = imageSlider
        ? imageSlider.childImageSharp.fluid
        : null;
      return (
        <BlockQuote
          key={`BlockQuote${index}`}
          activeStep={activeStep}
          index={index}
          imageToShow={imageToShow}
          step={step}
        />
      );
    })}
  </AutoPlaySwipeableViews>
);
export default BlockSwipeable;
