import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImgClients = styled(Img)`
  max-width: 110px;
  width: 100%;
  display: block;
  margin: 0 auto;
`;

const ClientBlock = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center', height: '110px' }}>
    <ImgClients fluid={data.childImageSharp.fluid} loading="lazy" />
  </div>
);

export default ClientBlock;
