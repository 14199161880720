import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/basics';
import BlockImages from './BlockImages/ContentImages';
import BlockClients from './BlockClients/BlockClients';
import BlockText from './BlockText';

const ContainerSubintro = styled(Container)`
  overflow: hidden;
  padding-top: 0rem;
  margin-top: -4rem;
  background: linear-gradient(
    to bottom,
    white,
    white 20%,
    #f4f4f4 20%,
    #f4f4f4 100%,
    transparent 100%
  );
`;
const SubintroBlocks = ({
  data: {
    markdownContent,
    dataImageMinLeft,
    dataImageMinRight,
    images,
    imagesMin,
    imagesClient,
  },
}) => {
  return (
    <ContainerSubintro>
      <BlockImages
        data={images}
        images={imagesMin}
        dataMinLeft={dataImageMinLeft.nodes}
        dataMinRight={dataImageMinRight.nodes}
      />
      <BlockText markdownContent={markdownContent} />
      <BlockClients images={imagesClient} />
    </ContainerSubintro>
  );
};
export default SubintroBlocks;
