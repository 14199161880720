import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { textBold } from 'styles';
import Markdown from 'utils/markdownToComponent';
import { breakpoint } from 'utils/mediaQueries';

const ContentText = styled(Grid)`
  flex: 0 0 auto;
  width: 100%;
  padding-top: 0rem;
  text-align: center;
  ${breakpoint.down('lg')`
  margin-right: 0;`}
`;
const Subtitle = styled(Typography)`
  ${textBold};
`;

const BlockText = ({ markdownContent }) => {
  return (
    <ContentText item xs={9} sm={6} md={6} lg={5}>
      <Subtitle variant="body1">
        {markdownContent.frontmatter.subTitle}
      </Subtitle>
      <Markdown variant="body1">{markdownContent.rawMarkdownBody}</Markdown>
    </ContentText>
  );
};
export default BlockText;
