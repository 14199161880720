import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import Img from 'gatsby-image';
import _ from 'lodash';
const ImageLeft = styled(Img)`
  position: absolute;
  max-width: 100px;
  &:nth-child(1) {
    left: 10rem;
    top: 5rem;
    ${breakpoint.down('lg')`
     left: 6rem`};
  }
  &:nth-child(2) {
    left: 0;
    top: -8rem;
    max-width: 80px;
    transform: rotate(-25deg);
    ${breakpoint.down('lg')`
  max-width: 60px; top: -5rem`};
  }
  &:nth-child(3) {
    left: 28rem;
    top: -20rem;
    ${breakpoint.down('lg')`
  top: -14rem; left: 19rem`};
  }
  ${breakpoint.down('lg')`
  max-width: 80px`};
`;
const BlockImagesMinLeft = ({ images, dataMinLeft }) => {
  return (
    <Grid>
      {dataMinLeft.map((imageMinLeft, index) => {
        const imageFind = _.find(images, function(image) {
          return image.base === imageMinLeft.frontmatter.relatedImage;
        });
        return (
          <ImageLeft
            key={`SubIntroImage${index}`}
            fluid={imageFind.childImageSharp.fluid}
          />
        );
      })}
    </Grid>
  );
};
export default BlockImagesMinLeft;
