import { Button } from 'components/basics';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import FormModal from 'components/pages/Index/FormModals/FormModal';
import VideoModal from 'components/pages/Index/VideoModal/VideoModal';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';

const ContentButton = styled(Grid)`
  width: 100%;
  position: relative;
  z-index: 1;
`;
const BlockButtonTestQemotion = ({ lang, justifyContent }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const handleClick = () => {
    navigate(`/${lang}/demoRequest`);
  };

  const handleVideoModalOpen = () => {
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
  };

  return (
    <ContentButton container justify={justifyContent}>
      <Button
        onClick={() => handleClick()}
        size="large"
        variant="contained"
        color="primary"
        mt={2}
      >
        <FormattedMessage id="button.testQemotion.longText" />
      </Button>
      &nbsp;&nbsp;
      <Button
        onClick={() => handleVideoModalOpen()}
        size="large"
        variant="outlined"
        color="primary"
        mt={2}
      >
        <FormattedMessage id="button.video" />
      </Button>
      <VideoModal
        lang={lang}
        open={videoModalOpen}
        handleClose={handleVideoModalClose}
      />
    </ContentButton>
  );
};
export default BlockButtonTestQemotion;
