import React from 'react';
import Layout from 'components/layout';
import IntroBlock from 'components/pages/Index/Intro/IntroBlock';
import ActionsBlocks from 'components/pages/Index/Actions/ActionsBlocks';
import LinkBlocks from 'components/LinkBlock/LinkBlocks';
import VideoBlocks from 'components/pages/Index/Video/VideoBlocks';
import TestimonialBlock from 'components/pages/Solution/Marketing/TestimonialBlock/TestimonialBlock';
import SubintroBlocks from 'components/pages/Index/SubIntro/SubintroBlocks';
import BlockButtonTestQemotion from 'components/BlockTestQemotion/BlockButtonTestQemotion';
import BlockButton from 'components/pages/Index/SubIntro/BlockButton';

const IndexPage = props => {
  const introBlocksData = {
    markdownContent: props.data.dataBlockIntro,
  };
  const subintroBlocksData = {
    markdownContent: props.data.dataBlockSubintro,
    dataImageMinLeft: props.data.dataImageMinLeftSubintro,
    dataImageMinRight: props.data.dataImageMinRightSubintro,
    images: props.data.imageSubintroBlock.nodes,
    imagesMin: props.data.imageMinSubintroBlock.nodes,
    imagesClient: props.data.imageClientSubintroBlock.nodes,
  };
  const actionBlocksData = {
    title: props.data.dataBlockActionTitle,
    markdownContent: props.data.dataBlockActionBody.nodes,
    image: props.data.imageActionBlock.childImageSharp.fluid,
  };
  const linkBlocksData = {
    markdownContent: props.data.dataBlockLinkBody,
  };
  const videoBlocksData = {
    title: props.data.dataBlockVideoTitle,
    markdownContent: props.data.dataBlockVideoBody.nodes,
    images: props.data.imageVideoBlock.nodes,
  };
  const testimonialBlockData = {
    markdownContent: props.data.dataTestimonialBlock.nodes,
    images: props.data.imageTestimonialBlock.nodes,
    imagesSliderQuotes:
      props.data.imagesQuotesTestimonialBlock &&
      props.data.imagesQuotesTestimonialBlock.nodes,
  };
  return (
    <Layout location={props.location} seo={props.seo}>
      <IntroBlock
        data={introBlocksData.markdownContent}
        lang={props.pageContext.langKey}
      />
      <SubintroBlocks
        data={subintroBlocksData}
        lang={props.pageContext.langKey}
      />
      <BlockButton lang={props.pageContext.langKey} />
      <ActionsBlocks data={actionBlocksData} />
      <TestimonialBlock
        data={testimonialBlockData}
        lang={props.pageContext.langKey}
        hideButton
      />
      <BlockButtonTestQemotion
        lang={props.pageContext.langKey}
        justifyContent={'center'}
      />
      <LinkBlocks
        data={linkBlocksData.markdownContent.nodes}
        lang={props.pageContext.langKey}
      />
      <VideoBlocks data={videoBlocksData} lang={props.pageContext.langKey} />
      {props.children}
    </Layout>
  );
};

export default IndexPage;
