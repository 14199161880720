import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Content from 'components/basics/Content';
import { breakpoint } from 'utils/mediaQueries';
import ImageTestimonial from 'components/pages/Solution/Marketing/TestimonialBlock/ImageTestimonial';
import BlockButtonClientCases from 'components/ButtonClientCase/BlockButtonClientCases';
import SliderBlock from 'components/pages/Solution/Marketing/SliderBlock/SliderBlock';

const GridImages = styled(Grid)`
  position: absolute;
  width: 100%;
  left: 0;
`;
const StyledGrid = styled(Grid)`
  padding: 1rem;
  z-index: 2;
  text-align: center;
  ${Content};
  ${breakpoint.down('md')`
  padding-top: 4rem;`}
`;
const ContentButton = styled(Grid)`
  padding-top: 6rem;
  padding-bottom: 12rem;
  ${Content}
  ${breakpoint.down('md')`
  padding-top: 3rem; padding-bottom: 10rem`};
`;

const QuoteBlock = styled.div`
  width: 100%;
  ${breakpoint.down('md')`
    width: 100%;
  `}
`;
const TestimonialBlock = ({ data, lang, hideButton }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <StyledGrid
        container
        justify="center"
        alignItems="center"
        direction="column"
      >
        <QuoteBlock>
          <SliderBlock data={data} centered />
        </QuoteBlock>
        {!hideButton && (
          <ContentButton container spacing={40} justify="center">
            <BlockButtonClientCases lang={lang} />
          </ContentButton>
        )}
      </StyledGrid>
      <GridImages container justify="flex-end">
        <ImageTestimonial images={data.images} />
      </GridImages>
    </Grid>
  );
};
export default TestimonialBlock;
