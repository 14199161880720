import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { breakpoint } from 'utils/mediaQueries';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/CloseRounded';
import { YoutubeVideoEmbed } from '../../../basics';

const StyledModal = styled(Modal)`
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ModalContainer = styled.div`
  position: absolute;
  padding: 1em;
  min-width: 400px;
  max-width: 80%;
  background-color: white;
  outline: none;
  border-radius: 1rem;
  ${breakpoint.down('sm')`
    width: 80%
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0.5rem;
`;
const StyledClose = styled(Close)`
  font-size: 2rem;
  font-weight: bold;
`;

function getModalStyle() {
  const top = 0;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
    margin: `2rem 0`,
  };
}

function getYoutubeTitle(lang) {
  switch (lang) {
    case 'fr':
      return 'Q° Emotion en 1 minute !';
    case 'en':
      return 'Q° Emotion in 1 minute !';
    case 'es':
      return '¡Q° Emotion en 1 minuto !';
    default:
      return 'Q° Emotion in 1 minute !';
  }
}

const VideoModal = ({ lang, open, handleClose }) => {
  return (
    <StyledModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <ModalContainer style={getModalStyle()}>
        <CloseButton>
          <Button onClick={handleClose}>
            <StyledClose fontSize="large" />
          </Button>
        </CloseButton>
        <br />
        <br />
        <YoutubeVideoEmbed
          youtubeID={lang === 'fr' ? 'IDFqZni7i74' : 'CyKr1bcfGdY'}
          youtubeTitle={getYoutubeTitle(lang)}
          width={'896'}
          height={'504'}
          loading="lazy"
        />
      </ModalContainer>
    </StyledModal>
  );
};

export default VideoModal;
