import React from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid } from '@material-ui/core';
import { textBold } from 'styles';
import { Container, Content } from 'components/basics';
import { breakpoint } from 'src/utils/mediaQueries';
import BlockButtonTestQemotion from 'components/BlockTestQemotion/BlockButtonTestQemotion';
const ContentBlock = styled(Grid)`
  ${Content}
`;

const TitleBlock = styled(Typography)`
  ${breakpoint.down('md')`text-align:left`}
  ${breakpoint.only('xxs')`    font-size: ${props =>
    `${props.theme.typography.h2.fontSize}px`};`}
  ${textBold}
  span {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const ParagraphBlock = styled(Grid)`
  margin-top: 8rem;
  ${breakpoint.down('md')`margin-top: 0rem`}
`;

const IntroBlock = ({ data, lang }) => {
  return (
    <Container>
      <ContentBlock container spacing={40} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <TitleBlock
            align="right"
            variant="h1"
            dangerouslySetInnerHTML={{ __html: data.frontmatter.title }}
          />
        </Grid>
        <ParagraphBlock item xs={12} sm={12} md={6} lg={5}>
          <Markdown>{data.rawMarkdownBody}</Markdown>
          <BlockButtonTestQemotion lang={lang} justifyContent={'flex-start'} />
        </ParagraphBlock>
      </ContentBlock>
    </Container>
  );
};

export default IntroBlock;
