import React from 'react';
import { subintroClients } from 'config/clients';
import _ from 'lodash';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import { Content } from 'components/basics';
import { breakpoints } from 'styles/breakpoints';
import { breakpoint } from 'utils/mediaQueries';
import ClientBlock from './ClientBlock';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { purple, white } from '../../../../../styles/colors';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';

const ContentClients = styled(Grid)`
  ${Content};
  max-width: 920px;
  padding-top: 2rem;
  padding-bottom: 5rem;
  ${breakpoint.down('md')`
  padding-top: 4rem;
   `};
`;

const Arrow = props => {
  const { className, style, onClick, left, right } = props;
  return (
    <>
      {left && (
        <ArrowBackIosOutlined
          className={className}
          style={{
            ...style,
            display: 'block',
            color: `${white}`,
            background: `${purple}`,
            borderRadius: '50%',
            height: '25px',
            width: '25px',
            padding: '3px',
          }}
          onClick={onClick}
        />
      )}
      {right && (
        <ArrowForwardIosOutlined
          className={className}
          style={{
            ...style,
            display: 'block',
            color: `${white}`,
            background: `${purple}`,
            borderRadius: '50%',
            height: '25px',
            width: '25px',
            padding: '3px',
          }}
          onClick={onClick}
        />
      )}
    </>
  );
};

const BlockClients = ({ images }) => {
  const widthWindow = typeof window !== 'undefined' ? window.innerWidth : null;
  const isMobile = widthWindow <= `${breakpoints.values.md}`;
  const clientLogos = [];
  subintroClients.forEach((client, index) => {
    const indexToFind = _.findIndex(images, ['base', client.base]);
    clientLogos.push(
      <ClientBlock key={`ClientBlock${index}`} data={images[indexToFind]} />
    );
  });

  const sliderOptions = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: isMobile ? 3 : 5,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    swipeToSlide: true,
    prevArrow: <Arrow left />,
    nextArrow: <Arrow right />,
    lazyLoad: true,
    // variableWidth: true,
  };

  return (
    <ContentClients
      container
      spacing={40}
      alignItems="center"
      justify="center"
      alignContent="center"
    >
      <div style={{ width: '75%', height: '100%' }}>
        <Slider {...sliderOptions}>{clientLogos}</Slider>
      </div>
    </ContentClients>
  );
};
export default BlockClients;
