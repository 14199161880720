import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/basics';
import React from 'react';
import { colors } from 'styles';

const getDirection = lang => {
  switch (lang) {
    case 'fr':
      return '/fr/blog/comparatif-analyse-semantique-manuelle-automatique';
    case 'en':
      return '/en/blog/comparison-manual-or-automatic-semantic-analysis';
    case 'es':
      return '/es/blog/comparacion-analisis-semantico-manual-automocatico';
    default:
      return '/en/blog/comparison-manual-or-automatic-semantic-analysis';
  }
};

const BlockButtonClientCases = ({ lang, body }) => {
  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      mt={2}
      component={Link}
      customBackgroundColor={colors.red}
      customHoverBackgroundColor={colors.shades.red.dark}
      to={body?.includes('blog') ? getDirection(lang) : `/${lang}/clientCase`}
    >
      <FormattedMessage
        id={
          body?.includes('blog')
            ? 'button.discoverBlogArticle'
            : 'button.discoverClientCase'
        }
      />
    </Button>
  );
};
export default BlockButtonClientCases;
