import React from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import { Grid } from '@material-ui/core';
import { textBold } from 'styles';
import { breakpoint } from 'src/utils/mediaQueries';
import { Content } from 'components/basics';
import Img from 'gatsby-image';

const ContentBlock = styled(Grid)`
  ${breakpoint.down('md')`
flex-direction:column`}
  ${Content}
`;
const ImgAction = styled(Grid)`
  padding: 5px;
  width: 400px;
  ${breakpoint.down('md')`
width:350px`}
  ${breakpoint.down('sm')`
width:300px`}
`;
const TitleAction = styled(Markdown)`
  width: 100%;
  padding: 5px;
  flex-grow: 1;
  line-height: 1;
  margin-bottom: 0;
  ${textBold};
  font-size: ${props => `${props.theme.typography.h5.fontSize}px`};
  .textBigger {
    font-size: ${props => `${props.theme.typography.h2.fontSize}px`};
  }
  strong {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const TitleActionBlock = ({ data, image }) => {
  return (
    <ContentBlock
      container
      wrap="nowrap"
      direction="row"
      alignItems="center"
      item
      xs={12}
      sm={10}
      md={10}
      lg={10}
    >
      <ImgAction>
        <Img fluid={image} loading="lazy" />
      </ImgAction>
      <TitleAction variant="h2">{data.rawMarkdownBody}</TitleAction>
    </ContentBlock>
  );
};

export default TitleActionBlock;
