import React from 'react';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { breakpoints } from 'styles/breakpoints';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import Img from 'gatsby-image';
import BlockImagesMinLeft from './BlockImagesMinLeft';
import BlockImagesMinRight from './BlockImagesMinRight';

const ContentImages = styled(Grid)`
  position: relative;
  margin-top: 2rem;
  flex-direction: row;
  height: 250px;
  width: 1920px;
  ${breakpoint.down('lg')`
  width:1280px; height: 400px`};
  ${breakpoint.down('md')`
  width:100%`};
  ${breakpoint.down('sm')`
  height: 80px`};
`;
const ImageSubintroBlock = styled(Grid)`
  width: 100%;
  max-width: 28%;
  position: absolute;
  &:nth-child(1) {
    left: 0;
    top: 0;
    ${breakpoint.down('lg')`
    top:0`};
    ${breakpoint.down('md')`
    top: 5rem`};
    ${breakpoint.down('sm')`
    top: 7rem`};
  }
  &:nth-child(2) {
    right: -1em;
  }
  ${breakpoint.down('lg')`
  max-width: 350px;`};
  ${breakpoint.down('md')`
  max-width: 300px;`};
  ${breakpoint.down('sm')`
  max-width: 200px;`};
`;
const Images = styled(Img)``;
const BlockImages = ({ data, images, dataMinLeft, dataMinRight }) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.md}px)`);
  return (
    <ContentImages container>
      {data.map((image, index) => {
        if (!isMobile) {
          return (
            <ImageSubintroBlock key={`ImageSubIntroBlock${index}`}>
              <Images fluid={image.childImageSharp.fluid} />
              <BlockImagesMinLeft images={images} dataMinLeft={dataMinLeft} />
            </ImageSubintroBlock>
          );
        }
        if (!isMobile && index === data.length - 1) {
          return (
            <ImageSubintroBlock key={`ImageSubIntroBlock${index}`}>
              <Images fluid={image.childImageSharp.fluid} />
              <BlockImagesMinRight
                images={images}
                dataMinRight={dataMinRight}
              />
            </ImageSubintroBlock>
          );
        }
        if (!isMobile) {
          return (
            <ImageSubintroBlock key={`ImageSubIntroBlock${index}`}>
              <Images fluid={image.childImageSharp.fluid} />
            </ImageSubintroBlock>
          );
        }
      })}
    </ContentImages>
  );
};
export default BlockImages;
