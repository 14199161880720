import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import Img from 'gatsby-image';
import _ from "lodash";

const ImageRight = styled(Img)`
  position: absolute;
  max-width: 100px;
  &:nth-child(1) {
    top: -1rem;
    left: 2rem;
    ${breakpoint.down('lg')`
    left: auto;`};
  }
  &:nth-child(2) {
    left: 13rem;
    top: 0;
    transform: rotate(15deg);
    max-width: 80px;
    ${breakpoint.down('lg')`
    left: 7rem; top:-1rem;`};
  }
  &:nth-child(3) {
    left: 25rem;
    top: -12rem;
    ${breakpoint.down('lg')`
    left: 15rem;top: -9rem`};
  }
`;
const BlockImagesMinRight = ({ images, dataMinRight }) => {
  return (
    <Grid>
      {dataMinRight.map((imageMinRight, index) => {
        const imageFind = _.find(images, function(image) {
          return image.base === imageMinRight.frontmatter.relatedImage;
        });
        return (
          <ImageRight
            key={`imageMinRight${index}`}
            fluid={imageFind.childImageSharp.fluid}
          />
        );
      })}
    </Grid>
  );
};
export default BlockImagesMinRight;
