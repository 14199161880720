import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import BackgroundSubintroBlock from 'images/indexPage/subIntroBlock/backgroundSubintroBlock.svg';
import { breakpoint } from 'utils/mediaQueries';
import BlockButtonClientCases from 'components/ButtonClientCase/BlockButtonClientCases';

const ContentButton = styled(Grid)`
  padding-bottom: 8rem;
  background-repeat: no-repeat;
  background-image: url('${BackgroundSubintroBlock}');
  background-position: top;
  background-size: contain;
    > a {
      margin-top: 1.5rem;
      ${breakpoint.down('lg')`
      margin-top: 0rem;`};
      ${breakpoint.down('sm')`
        margin-top: 0;`};
    }
  ${breakpoint.down('md')`
    padding-bottom: 4rem;`};
`;
const ButtonClient = styled(BlockButtonClientCases)``;
const BlockButton = ({ lang }) => {
  return (
    <ContentButton container justify="center">
      <ButtonClient lang={lang} />
    </ContentButton>
  );
};
export default BlockButton;
