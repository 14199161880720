import React from 'react';
import { Grid } from '@material-ui/core';
import { Content, Container } from 'components/basics';
import styled from 'styled-components';
import BackgroundVideoBlock from 'images/indexPage/videoBlock/backgroundVideoBlock.svg';
import VideoBlock from './VideoBlock';
import AvisBlock from './AvisBlock';
import { breakpoint } from 'utils/mediaQueries';
import { breakpoints } from 'styles/breakpoints';

const StyledContainer = styled(Container)`
  background-repeat: no-repeat;
  background-image: url('${BackgroundVideoBlock}');
  background-position: bottom;
  background-size: contain;
  ${breakpoint.down('md')`
  background-size: 200% 170%;background-position:unset;
  `};
@media screen and (min-width: ${breakpoints.values.md}px) and (max-width: ${
  breakpoints.values.lg
}px)and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // IE10+ CSS here
    background-position: 0 0;
    background-size: 120% 170%;
}
@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${
  breakpoints.values.md
}px)and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // IE10+ CSS here
    background-position: 0 0;
    background-size: 200% 170%;
}
`;

const ContentBlock = styled(Grid)`
  padding-top: 8rem;
  padding-bottom: 8rem;
  ${Content}
`;

const VideoBlocks = ({ title, data, lang }) => {
  return (
    <StyledContainer>
      <ContentBlock container spacing={40} justify="center">
        <VideoBlock />
        <AvisBlock data={(title, data)} lang={lang} />
      </ContentBlock>
    </StyledContainer>
  );
};
export default VideoBlocks;
