import React from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import { Grid, Typography } from '@material-ui/core';
import { colors, textBold } from 'styles';
import { breakpoint } from 'src/utils/mediaQueries';
import { Content } from 'components/basics';

const ContentBlock = styled(Grid)`
  text-transform: uppercase;
  ${Content}
  ${breakpoint.only('xs')`min-width:250px;`}
  ${breakpoint.only('xxs')`min-width:200px;`}
`;
const NumAction = styled(Typography)`
  width: 60px;
  color: ${props => colors[props.customColor]};
  font-size: ${props => `${props.theme.typography.h5.fontSize}px`};
  ${textBold}
`;

const StyledMarkdown = styled(Markdown)`
  padding: 5px;
  flex-grow: 1;
  ${textBold};
  font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`};
  strong {
    color: ${props => props.theme.palette.secondary.main};
  }
  span {
    color: ${props => props.theme.palette.secondary.main};
    font-family: ${props => props.theme.typography.fontFamily};
  }
  line-height: 1.5em;
`;

const ActionsBlock = ({ data }) => {
  return (
    <ContentBlock
      container
      wrap="nowrap"
      direction="row"
      item
      xs={'auto'}
      sm={6}
      md={6}
      lg={3}
    >
      <NumAction variant="h5">{data.frontmatter.numBlockAction}.</NumAction>
      <StyledMarkdown>{`## ${data.rawMarkdownBody}`}</StyledMarkdown>
    </ContentBlock>
  );
};

export default ActionsBlock;
