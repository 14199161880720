import React from 'react';
import ActionsBlock from './ActionsBlock';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Container, Content } from 'components/basics';
import { breakpoint } from 'src/utils/mediaQueries';
import TitleActionBlock from './TitleActionBlock';

const ContentBlock = styled(Grid)`
  padding-top: 4rem;
  width: 100% !important;
  ${breakpoint.down(
    'sm'
  )`flex-wrap:nowrap;overflow-x:scroll;justify-content:flex-start`}
  //${Content}
  max-width: 1680px;
`;
const ContentTitle = styled(Grid)`
  ${Content}
`;
const ContainerAction = styled(Container)`
  margin-top: -6rem;
  ${breakpoint.down('md')`
  margin-top: 1rem;`};
`;

const ActionsBlocks = ({ data: { title, markdownContent, image }, lang }) => {
  return (
    <ContainerAction>
      <ContentTitle container spacing={40} justify="center">
        <TitleActionBlock data={title} lang={lang} image={image} />
      </ContentTitle>
      <ContentBlock container spacing={40} justify="center">
        {markdownContent.map((block, index) => (
          <ActionsBlock key={`ActionsBlock${index}`} data={block} lang={lang} />
        ))}
      </ContentBlock>
    </ContainerAction>
  );
};

export default ActionsBlocks;
